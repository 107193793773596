import "../HomeComponents.css";
import MenuDropdown from "./MenuDropdown";
import { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { UseCartContext } from "../cart-components/CartContext";

export default function Heading({ title, handleSetShowProfile, handleSetShowOrder }) {
  const {showCart, cartQty} = UseCartContext()

  const [showMenuDropDown, setShowMenuDropdown] = useState(false);
  const imgSrc = localStorage.getItem("user_img");
  const handleSetShowMenuDropDown = () => {
    setShowMenuDropdown(!showMenuDropDown);
  };

  return (
    <nav
      className="navbar fixed-top bg-neutral-100/80 backdrop-blur-md"
      style={{
        boxShadow: "0px 3px 7px 0px rgba(0, 0, 0, 0.3)",
        height: "56px",
        padding: "8px",
      }}
    >
      <Link className="navbar-brand px-1" to="/">
        <img
          src={process.env.PUBLIC_URL + "/images/app-logo-2.png"}
          width="30"
          height="30"
          className="d-inline-block align-top"
          alt=""
        />
        <span className="px-2" style={{ fontWeight: "500" }}>
          {title}
        </span>
      </Link>

      <div className="d-flex align-items-center">
        <button type="button" className="shopping-cart-btn" onClick={showCart}>
          <div className="icon">
            <span>
              <FontAwesomeIcon icon={faCartShopping} color={"#292933"} />
            </span>
          </div>
          <div className="cart-counter rounded-circle d-flex justify-content-center align-items-center">
            {cartQty}
          </div>
        </button>

        <button onClick={handleSetShowMenuDropDown} >
          <div className="profile-img-div">
            <img src={imgSrc} alt="profile" />
          </div>
          {showMenuDropDown ? (
          <MenuDropdown handleSetShowProfile={handleSetShowProfile} handleSetShowOrder={handleSetShowOrder}/>
        ) : (
          ""
        )}
        </button>

      </div>
    </nav>
  );
}
