import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignIn,
  faPhone,
  faQuestion,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "./AuthContext";

import {
  APP_NAME,
  CUSTOMER_SUPPORT_PHONE,
  ABOUT_US_LINK,
} from "../common/ClientData";

export default function AuthNavbar() {
  const {showLogin, showLandingPage, toggleShowLoginOrProducts, toggleShowLandingPage} = useAuth()

  const [showMenuDropDown, setShowMenuDropdown] = useState(false);

  const handleSetShowMenuDropdown = () => {
    setShowMenuDropdown(!showMenuDropDown);
  };
  return (
    <nav
      className="navbar fixed-top navbar-expand-lg bg-neutral-100/80 backdrop-blur-md"
      style={{
        boxShadow: "0px 3px 7px 0px rgba(0, 0, 0, 0.3)",
        height: "56px",
        padding: "8px",
      }}
    >
      <Link className="navbar-brand" to="/">
        <img
          src={process.env.PUBLIC_URL + "/images/app-logo-2.png"}
          width="30"
          height="30"
          className="d-inline-block align-top"
          alt=""
        />
        <span className="px-2" style={{ fontWeight: "500" }}>
          {APP_NAME}
        </span>
      </Link>

      <div>
        <button
          className="navbar-toggler"
          type="button"
          onClick={handleSetShowMenuDropdown}
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* for mobile view */}
        {showMenuDropDown && (
          <div className="dropdown-wrapper d-block d-lg-none">
            <ul className="menu-bar text-center">
              <div className="list-div" style={{ borderRadius: "25px" }}>
                <li>
                  <Link
                    className={`nav-link ${!showLandingPage ? "active" : ''}`}
                    onClick={() => toggleShowLoginOrProducts()}
                  >
                    {showLogin ? (
                      <div className="container d-flex align-items-center">
                        <div>
                          <div className="icon">
                            <span>
                              <FontAwesomeIcon icon={faTag} color={"#fff"} />
                            </span>
                          </div>
                        </div>
                        <div>
                          <label className="responsive-label">
                            <strong>View Products</strong>
                          </label>
                        </div>
                      </div>
                    ) : (
                      <div className="container d-flex align-items-center">
                        <div>
                          <div className="icon">
                            <span>
                              <FontAwesomeIcon icon={faSignIn} color={"#fff"} />
                            </span>
                          </div>
                        </div>
                        <div>
                          <label className="responsive-label">
                            <strong>Log In</strong>
                          </label>
                        </div>
                      </div>
                    )}
                  </Link>
                </li>
              </div>
              <div className="list-div" style={{ borderRadius: "25px" }}>
                <li>
                  <Link
                    className="nav-link"
                    to={`tel:${CUSTOMER_SUPPORT_PHONE}`}
                  >
                    <div className="container d-flex align-items-center">
                      <div>
                        <div className="icon">
                          <span>
                            <FontAwesomeIcon icon={faPhone} color={"#fff"} />
                          </span>
                        </div>
                      </div>
                      <div>
                        <label className="responsive-label">
                          <strong>Customer Support</strong>
                        </label>
                      </div>
                    </div>
                  </Link>
                </li>
              </div>
              <div className="list-div" style={{ borderRadius: "25px" }}>
                <li>
                  <Link
                    className={`nav-link ${showLandingPage ? "active" : ''}`}
                    onClick={() => toggleShowLandingPage()}                    
                  >
                    <div className="container d-flex align-items-center">
                      <div>
                        <div className="icon">
                          <span>
                            <FontAwesomeIcon icon={faQuestion} color={"#fff"} />
                          </span>
                        </div>
                      </div>
                      <div>
                        <label className="responsive-label">
                          <strong>About Us</strong>
                        </label>
                      </div>
                    </div>
                  </Link>
                </li>
              </div>
            </ul>
          </div>
        )}
      </div>

      {/* normal view */}
      <div className="collapse navbar-collapse">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <Link
              className={`nav-link ${!showLandingPage ? "active" : ''}`}
              onClick={() => toggleShowLoginOrProducts()}
            >
              {showLogin ? (
                <div className="container d-flex align-items-center">
                  <div>
                    <div className="icon">
                      <span>
                        <FontAwesomeIcon icon={faTag} />
                      </span>
                    </div>
                  </div>
                  <div>
                    <label className="responsive-label">
                      <strong>View Products</strong>
                    </label>
                  </div>
                </div>
              ) : (
                <div className="container d-flex align-items-center">
                  <div>
                    <div className="icon">
                      <span>
                        <FontAwesomeIcon icon={faSignIn} />
                      </span>
                    </div>
                  </div>
                  <div>
                    <label className="responsive-label">
                      <strong>Log In</strong>
                    </label>
                  </div>
                </div>
              )}
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={`tel:${CUSTOMER_SUPPORT_PHONE}`}>
              <div className="container d-flex align-items-center">
                <div>
                  <div className="icon">
                    <span>
                      <FontAwesomeIcon icon={faPhone} />
                    </span>
                  </div>
                </div>
                <div>
                  <label className="responsive-label">
                    <strong>Customer Support</strong>
                  </label>
                </div>
              </div>
            </Link>
          </li>
          <li className="nav-item">
            <Link className={`nav-link ${showLandingPage? 'active' : ''}`} onClick={() => toggleShowLandingPage()}>
              <div className="container d-flex align-items-center">
                <div>
                  <div className="icon">
                    <span>
                      <FontAwesomeIcon icon={faQuestion} />
                    </span>
                  </div>
                </div>
                <div>
                  <label className="responsive-label">
                    <strong>About Us</strong>
                  </label>
                </div>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}
