// const API_BASE_URL = 'http://127.0.0.1:8000';
// const FRONTEND_URL = 'http://localhost:3000';
const API_BASE_URL = 'https://ad.vitalgap.com';
const FRONTEND_URL = 'https://ad-store.vitalgap.com';

// DRF DEFAULT AUTH ENDPOINTS HANDLED BY DJOSER:
export const LOGOUT_ENDPOINT = `${API_BASE_URL}/auth/token/logout/`;

// DRF JWT ENDPOINT HANDLED BY DJOSER:
export const LOGIN_ENDPOINT = `${API_BASE_URL}/auth/jwt/create/`;
export const VERIFY_TOKEN_ENDPOINT = `${API_BASE_URL}/auth/jwt/verify/`;
export const REFRESH_TOKEN_ENDPOINT = `${API_BASE_URL}/auth/jwt/refresh/`;

export const REGISTER_ENDPOINT = `${API_BASE_URL}/auth/users/`;
export const ACTIVATION_ENDPOINT = `${API_BASE_URL}/auth/users/activation/`
export const RESEND_ACTIVATION_MAIL_ENDPOINT = `${API_BASE_URL}/auth/users/resend_activation/`

export const USER_PROFILE_ENDPOINT = `${API_BASE_URL}/auth/users/me/`;

export const PASSWORD_RESET_ENDPOINT = `${API_BASE_URL}/auth/users/reset_password/`
export const PASSWORD_RESET_CONFIRM_ENDPOINT = `${API_BASE_URL}/auth/users/reset_password_confirm/`

// authentication urls
export const USER_PROFILE_UPDATE_ENDPOINT = `${API_BASE_URL}/authentication/update-user-profile/`
export const CUSTOMER_ENDPOINT = `${API_BASE_URL}/authentication/customer`

// product urls
export const CATEGORY_LIST = `${API_BASE_URL}/product/category-list`
export const PRODUCT_LIST = `${API_BASE_URL}/product/product-list`
export const PRODUCT_DETAILS = `${API_BASE_URL}/product/product-details/`
export const GET_VARIANT = `${API_BASE_URL}/product/variant/`

// sales urls
export const ORDER = `${API_BASE_URL}/sales/order`
export const ORDER_CONFIRMATION_DOWNLOAD = `${API_BASE_URL}/sales/order-confirmation-download/`







